import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Flex,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  Picto,
  Switch,
  Text,
  useDisclosure
} from '@stars-ecom/shared-atoms-ui'
import dayjs from 'dayjs'
import { navigate } from 'gatsby'
import first from 'lodash/first'
import get from 'lodash/get'
import React, { useContext, useEffect, useState } from 'react'

import LoginPopin from '../account/LoginPopin'
import { MaxProductModal } from '../cart'
import { ApiContext, CartContext, DataLayerUtils, UserContext, WebsiteContext, updateOrder } from '../context'
import { OfferUtils, priceFormat } from '../utils'
import LoyaltyPopin from './LoyaltyPopin'

const CheckoutCartSummary = (props) => {
  const {
    setAlert = () => {},
    onOpenLoyalty = () => {},
    onCloseLoyalty = () => {},
    isOpenLoyalty = false,
    loyaltyCard,
    loyaltyPage,
    errorManager
  } = props
  const [promoCode, setPromoCode] = useState('')
  const [loyaltyLoading, setLoyaltyLoading] = useState(false)
  const [couponLoading, setCouponLoading] = useState(false)
  const [hasLoyaltyCard, setHasLoyaltyCard] = useState(false)
  const [loading, setLoading] = useState(false)
  const websiteContext = useContext(WebsiteContext)
  const currentUser = useContext(UserContext)
  const apiContext = useContext(ApiContext)
  const currentCart = useContext(CartContext)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const {
    isOpen: isOpenOrderLimitErrorModal,
    onOpen: onOpenOrderLimitErrorModal,
    onClose: onCloseOrderLimitErrorModal
  } = useDisclosure()
  const [{ standardPrice = 0, price: loyaltyPrice = 0, currency = 'EUR' }, setLoyaltyCardOffer] =
    useState(
      OfferUtils.getCurrentProductOffer(
        loyaltyCard || {},
        'STANDARD',
        process.env.GATSBY_API_CHANNEL_ID,
        currentUser
      )
    )

  useEffect(() => {
    const temp = OfferUtils.getCurrentProductOffer(
      loyaltyCard || {},
      'STANDARD',
      process.env.GATSBY_API_CHANNEL_ID,
      currentUser
    )
    setLoyaltyCardOffer(temp)
  }, [currentUser, loyaltyCard])

  const handlePromo = async (e) => {
    e.preventDefault()
    if (currentCart?.couponCodes?.length > 0 || currentCart?.discounts?.length >= 1) {
      errorManager.setErrorMessage(
        (currentCart?.couponCodes?.length === 0 && currentCart?.discounts?.length >= 1) ? 
        'Une promotion est déjà appliquée dans votre panier.' : 
        'Un code promo se trouve déjà dans votre panier.'
      )
      return
    }
    
    setCouponLoading(true)
    const result = await apiContext?.OrderApi?.applyCouponCode(promoCode)
    const error = result?.errorCode || result?.error
    if (error) {
      if (error === 'COUPON_CODE_LIMIT_ERROR' || error === 'COUPON_CODE_ALREADY_APPLIED_ERROR') {
        errorManager.setErrorMessage('Vous ne pouvez plus bénéficier des avantages liés à ce code promo. Celui-ci a déjà été utilisé.')
      } else if (error === 'COUPON_CODE_NOT_APPLICABLE_ERROR') {
        errorManager.setErrorMessage('Vous ne pouvez pas bénéficier des avantages liés à ce code promo.')
      } else if (error === 'COUPON_CODE_EXPIRED_ERROR') {
        errorManager.setErrorMessage("Le code que vous avez saisi n'est plus valide.")
      } else if (error === "COUPON_CODE_CONDITION_ERROR") {
        errorManager.setErrorMessage("Votre commande ne satisfait pas aux conditions d'application du code promo.")
      } else {
        errorManager.setErrorMessage("Le code que vous avez saisi n'existe pas ou n'est pas valable.")
      }
      setPromoCode('')
    } else {
      errorManager.setErrorMessage('')
    }
    setCouponLoading(false)
  }

  const removePromo = async (couponToRemove) => {
    setCouponLoading(true)
    errorManager.setErrorMessage('')
    await apiContext?.OrderApi?.removeCouponCode(couponToRemove)
    setCouponLoading(false)
  }

  const toggleLoyalty = async () => {
    const orderLine = checkLoyaltyCard()
    setLoyaltyLoading(true)
    if (orderLine) {
      await apiContext?.OrderApi?.removeOrderLine(orderLine.id)
    } else {
      if (currentCart.totalQuantity >= 10) {
        onOpenOrderLimitErrorModal()
      } else {
        await apiContext?.OrderApi?.addItemToOrder(get(loyaltyCard, 'variants.0.id'), 1)
      }
    }
    setLoyaltyLoading(false)
  }

  const checkLoyaltyCard = () => {
    const loyaltyCardInCart = get(currentCart, 'lines')?.find(
      (ol) => ol?.productVariant?.id === get(loyaltyCard, 'variants.0.id')
    )
    if (loyaltyCardInCart) {
      setHasLoyaltyCard(true)
      return loyaltyCardInCart
    } else {
      setHasLoyaltyCard(false)
      return false
    }
  }

  const confirmBasket = async () => {
    setLoading(true)
    if (currentUser && get(currentUser, 'id')) {
      const billingAddress = currentUser.addresses.find((a) => a.defaultBillingAddress)
      if (!billingAddress || !billingAddress?.id) {
        navigate('/checkout/account/edit', { replace: true })
      } else {
        const checkInventory = await apiContext?.OrderApi?.validateCartInventory()
        if (checkInventory?.message) {
          setAlert({ message: checkInventory?.message, status: 'error' })
          setLoading(false)
          return
        } else {
          navigate('/checkout/shipping', { replace: true })
        }
      }
    } else {
      setLoading(false)
      onOpen()
    }
  }

  useEffect(() => {
    checkLoyaltyCard()
  }, [currentCart])

  useEffect(() => {
    if (currentUser && currentUser.id) {
      onClose()
    }
  }, [currentUser])

  const analyticEvent = () => {
    DataLayerUtils.addShoppingCartLoginEvent({
      order: currentCart,
      command: 'checkoutStep',
      customer: currentUser,
      checkoutStepNumber: 2,
      checkoutStepName: 'Identification'
    })
  }
  return (
    <Flex
      width={{ base: '100%', md: 'auto' }}
      p="10px 20px 20px"
      backgroundColor="#F6F6F6"
      flexDirection="column"
      height="fit-content"
      fontFamily="PT Sans, Arial, sans-serif"
      minWidth="315px">
      <Flex pb="7px" justifyContent="space-between" alignItems="center">
        <Text>Total articles</Text>
        <Text
          fontWeight="bold"
          fontSize="24px"
          fontFamily="PT Sans Narrow,PT Sans,Arial,sans-serif">
          {priceFormat(
            (currentCart?.subTotalWithTax || 0) -
              currentCart?.discounts?.reduce((a, c) => a + c?.amountWithTax, 0),
            currency
          )}
        </Text>
      </Flex>
      <Divider borderColor="#d4d4d4" />
      <Flex py="18px" flexDirection="column">
        <Flex
          justify="flex-start"
          align={
            currentUser?.customFields?.loyaltyIsRenew &&
            currentUser?.customFields?.loyaltyStatus &&
            !hasLoyaltyCard
              ? 'flex-end'
              : 'unset'
          }>
          {currentUser?.customFields?.loyaltyStatus &&
          !currentUser?.customFields?.loyaltyIsRenew ? (
            <Switch
              isChecked={true}
              disabled={true}
              sx={{
                '& > span': {
                  backgroundColor: '#39a132 !important'
                },
                '& > span:focus, & > span[data-focus]': {
                  boxShadow: 'none !important'
                }
              }}
            />
          ) : loyaltyLoading ? (
            <CircularProgress
              isIndeterminate={true}
              size="34px"
              color={websiteContext?.mainColor}
            />
          ) : (
            <Switch
              top={
                currentUser?.customFields?.loyaltyIsRenew &&
                currentUser?.customFields?.loyaltyStatus &&
                !hasLoyaltyCard
                  ? '-8px'
                  : 'unset'
              }
              isChecked={hasLoyaltyCard}
              onChange={toggleLoyalty}
              sx={{
                '& > span': {
                  backgroundColor: hasLoyaltyCard ? '#39a132 !important' : '#000'
                },
                '& > span:focus, & > span[data-focus]': {
                  boxShadow: 'none !important'
                }
              }}
            />
          )}
          {/* <CLIENT NON FID> */}
          <Flex ml="10px" flexDirection="column">
            {!currentCart?.customFields?.isLoyalty && !currentUser?.customFields?.loyaltyIsRenew && (
              <Link onClick={onOpenLoyalty} style={{ cursor: 'pointer', lineHeight: '1.2' }}>
                <Text fontSize="12px" textDecoration="underline">
                  <strong>{`Adhérez à la carte fidélité pour ${priceFormat(
                    loyaltyPrice,
                    currency
                  )}`}</strong>
                  {standardPrice !== loyaltyPrice && (
                    <Box display="inline">
                      <Text fontSize="12px" as="span">
                        {` au lieu de `}
                      </Text>
                      <Text as="span" fontSize="12px" textDecoration="line-through">
                        {`${priceFormat(standardPrice, currency)}`}
                      </Text>
                    </Box>
                  )}
                </Text>
              </Link>
            )}
            {currentCart?.customFields?.isLoyalty && !currentUser?.customFields?.loyaltyIsRenew && (
              <Text fontSize="12px" as="u">
                <strong>Vous avez adhéré à la carte fidélité</strong>
              </Text>
            )}
            {currentCart?.customFields?.totalDefaultLoyaltyDiscount !== 0 &&
              !currentUser?.customFields?.loyaltyIsRenew && (
                <Box fontSize="11px" w="100%" color="grey">
                  et économisez dès aujourd&apos;hui&nbsp;
                  <Text fontWeight="bold" color="orange" as="span">
                    {priceFormat(
                      currentCart?.customFields?.totalDefaultLoyaltyDiscount || 0,
                      currency
                    )}
                  </Text>
                </Box>
              )}
            {/* </CLIENT NON FID> */}
            {/* <CLIENT RENOUVELLEMENT> */}
            {currentUser?.customFields?.loyaltyIsRenew && hasLoyaltyCard && (
              <Link onClick={onOpenLoyalty} style={{ cursor: 'pointer', lineHeight: '1' }}>
                <Text fontSize="12px" as="u">
                  <strong>Vous renouvelez votre adhésion à la carte de fidélité</strong>
                </Text>
              </Link>
            )}
            {currentUser?.customFields?.loyaltyIsRenew &&
              currentCart?.customFields?.totalDefaultLoyaltyDiscount !== 0 && (
                <Box
                  fontSize="11px"
                  w="100%"
                  color="grey"
                  order={currentUser?.customFields?.loyaltyStatus ? 'unset' : '2'}>
                  {hasLoyaltyCard || !currentUser?.customFields?.loyaltyStatus ? 'et' : 'Vous'}{' '}
                  économisez dès aujourd&apos;hui&nbsp;
                  <Text fontWeight="bold" color="orange" as="span">
                    {priceFormat(
                      currentCart?.customFields?.totalDefaultLoyaltyDiscount || 0,
                      currency
                    )}
                  </Text>
                  {!hasLoyaltyCard && currentUser?.customFields?.loyaltyStatus && (
                    <span>
                      &nbsp;avec <span style={{ fontWeight: 'bold' }}>la carte fidélité</span>
                    </span>
                  )}
                  {!hasLoyaltyCard && currentUser?.customFields?.loyaltyStatus && (
                    <Text>
                      Votre adhésion expire le{' '}
                      {dayjs(currentUser?.customFields?.loyaltyEndDate, 'YYYY-MM-DD').format(
                        'DD/MM/YY'
                      )}
                    </Text>
                  )}
                </Box>
              )}
            {currentUser?.customFields?.loyaltyIsRenew && !hasLoyaltyCard && (
              <Link onClick={onOpenLoyalty} style={{ cursor: 'pointer', lineHeight: '1' }}>
                <Text fontSize="12px" as="u">
                  <strong>{`Renouvelez votre adhésion à la carte fidélité pour ${priceFormat(
                    loyaltyPrice,
                    currency
                  )}`}</strong>
                  {standardPrice != loyaltyPrice && (
                    <Box display="inline">
                      <Text fontSize="12px" as="span">
                        &nbsp;au lieu de&nbsp;
                      </Text>
                      <Text as="span" fontSize="12px" textDecoration="line-through">
                        {`${priceFormat(standardPrice, currency)}`}
                      </Text>
                    </Box>
                  )}
                </Text>
              </Link>
            )}
            {/* </CLIENT RENOUVELLEMENT> */}
          </Flex>
        </Flex>
        <form onSubmit={handlePromo}>
          <InputGroup size="md" mt="20px">
            <Input
              value={promoCode}
              pr="72px"
              placeholder="Vous avez un code promo ?"
              backgroundColor="white"
              fontSize="12px"
              fontStyle="italic"
              onChange={(e) => setPromoCode(e.target.value)}
              disabled={couponLoading}
            />
            <InputRightElement>
              <Button
                fontSize="12px"
                fontWeight="lighter"
                w="30px"
                h="38px"
                mr="2px"
                color="white"
                backgroundColor="black"
                type="submit"
                borderLeftRadius="0"
                _focus={{ border: 'none' }}
                isLoading={couponLoading}
                disabled={couponLoading}>
                OK
              </Button>
            </InputRightElement>
          </InputGroup>
        </form>
        {errorManager.errorMessage && (
          <Box fontFamily="PT Sans, Arial, Sans-serif" fontSize="13px" color="red">
            {errorManager.errorMessage}
          </Box>
        )}
        {(currentCart?.couponCodes.length === 0 && currentCart?.discounts.length > 0) && (
          <Flex
            direction="row"
            fontSize="12px"
            mt="12px"
            justifyContent="space-between"
            w="100%">
            <Flex direction="row" justify="space-between" width="100%">
              <Flex width="80%">
                <Text as="span" fontStyle="italic" style={{ color: "#cd2acd" }}>
                  Vous bénéficiez de l'offre : {first(currentCart?.discounts)?.description}
                </Text>
              </Flex>
              <Text as="span" fontWeight={700}>
                {priceFormat(first(currentCart?.discounts)?.amountWithTax, currency)}
              </Text>
            </Flex>
          </Flex>
        )}
        {currentCart?.couponCodes.length > 0 && (
          <Flex
            direction="row"
            fontSize="12px"
            mt="20px"
            pl="14px"
            justifyContent="space-between"
            w="100%">
            <Picto color="#39a132" width="20px" height="20px" icon="check" />
            <Flex direction="row" ml="12px" justify="space-between" width="100%">
              <Flex>
                <Text as="span" pr="12px" maxW="150px">
                  <strong>OFFRE</strong> : {first(currentCart?.discounts)?.description ? first(currentCart?.discounts)?.description : first(currentCart.couponCodes)}
                </Text>
                {couponLoading ? (
                  <CircularProgress
                    size="16px"
                    isIndeterminate={true}
                    color={websiteContext?.mainColor}
                  />
                ) : (
                  <Picto
                    color="#999999"
                    width="16px"
                    height="16px"
                    icon="trashBin"
                    onClick={() => removePromo(first(currentCart?.couponCodes))}
                    style={{ cursor: 'pointer' }}
                  />
                )}
              </Flex>
              <Text as="span" textAlign="right" fontWeight={700}>
                {priceFormat(first(currentCart?.discounts)?.amountWithTax, currency)}
              </Text>
            </Flex>
          </Flex>
        )}
        <Flex
          direction="row"
          w="100%"
          fontSize="12px"
          mt="20px"
          pl="7px"
          justifyContent="space-between">
          <Picto width="30px" height="20px" icon="delivery" />
          <Box w="100%" ml="11px">
            <Flex direction="row" justify="space-between">
              <Text as="span" fontWeight="700" textDecoration="underline">
                Livraison à partir de{' '}
              </Text>
              <Text as="span" fontWeight={700} color="#808080">
                {priceFormat(currentCart?.customFields?.totalDefaultShippingFee, currency, "Offert")}
              </Text>
            </Flex>
            <Text color="grey" fontSize="11px">
              {"calculé à l'étape suivante"}
            </Text>
          </Box>
        </Flex>
      </Flex>
      <Divider borderColor="#d4d4d4" />
      <Flex py="20px" justify="space-between" align="center">
        <Text fontFamily="PT Sans" fontSize="18px" fontWeight="700">
          Total commandé
        </Text>
        <Text
          fontWeight="bold"
          fontSize="30px"
          fontFamily="PT Sans Narrow,PT Sans,Arial,sans-serif">
          {priceFormat(
            currentCart?.subTotalWithTax + currentCart?.customFields?.totalDefaultShippingFee,
            currency
          )}
        </Text>
      </Flex>
      <Box textAlign="center">
        <Button
          id="validateCart"
          onClick={confirmBasket}
          disabled={currentCart?.totalQuantity === 0}
          backgroundColor={websiteContext.mainColor}
          fontFamily={websiteContext.fontFamily}
          _hover={{ bg: websiteContext.darkColor }}
          _focus={{ border: 'none' }}
          h={{ base: '40px', md: '50px' }}
          color="#fff"
          fontSize="16px"
          w="100%"
          borderRadius="0px">
          VALIDER LE PANIER
          {loading && (
            <Flex
              position="absolute"
              w="100%"
              h="100%"
              justify="center"
              align="center"
              background="rgba(0,0,0,0.1)">
              <CircularProgress
                isIndeterminate={true}
                size="20px"
                color={websiteContext?.mainColor}
              />
            </Flex>
          )}
        </Button>
      </Box>
      <LoginPopin
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        goBackOnClose={false}
        analyticEvent={analyticEvent}
      />
      <LoyaltyPopin
        isOpen={isOpenLoyalty}
        onClose={onCloseLoyalty}
        loyaltyPage={loyaltyPage}
        isRenew={currentUser?.customFields?.loyaltyIsRenew}
      />
      <MaxProductModal
        isOpen={isOpenOrderLimitErrorModal}
        onOpen={onOpenOrderLimitErrorModal}
        onClose={onCloseOrderLimitErrorModal}
      />
    </Flex>
  )
}

export default CheckoutCartSummary
