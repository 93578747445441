import './price.css'

import { Flex, Text } from '@stars-ecom/shared-atoms-ui'
import React, { memo, useEffect, useState, useContext } from 'react'
import { WebsiteContext } from '../../context'

import { OfferUtils, priceFormat } from '../../utils'
import { PopoverEcopart } from './Ecopart'

const RedPoint = () => (
  <div
    style={{
      backgroundColor: '#b30f16',
      borderRadius: '5px',
      width: '10px',
      height: '10px',
      marginRight: '6px'
    }}
  />
)

const LoyaltyAdvantage = ({ channelLoyaltyAdvantage }) => {
  return (
    <Flex
      w={{ base: '100%', md: 'auto' }}
      bgColor="orange"
      justify="center"
      alignSelf="flex-end"
      align="center"
      pl="3px"
      pr="3px"
      h="26px"
      mr={{ base: '4px', md: '7px' }}
      style={{ borderRadius: '3px' }}>
      <Text
        as="span"
        fontSize={{ base: '15.5px', md: '18px' }}
        style={{
          fontWeight: '700',
          color: '#242a30'
        }}>-{channelLoyaltyAdvantage}</Text>
      <Text
        as="span"
        fontSize="9px"
        style={{
          fontWeight: '700',
          color: '#242a30',
          paddingBottom: '7px'
        }}>
        %
      </Text>
      <Text
        letterSpacing={{ base: '0.25px', md: '0.5px' }}
        as="span"
        fontSize="10px"
        maxW={{ base: '92px', md: '87px' }}
        style={{
          lineHeight: '10px',
          fontWeight: 700,
          color: '#242a30',
          marginLeft: '5px'
        }}>
        Supplémentaires carte fidélité
      </Text>
    </Flex>
  )
}

const CheckoutPrice = (props) => {
  const { orderLine = {}, isLoyalty, currentUser } = props
  const websiteContext = useContext(WebsiteContext);

  const productVariant = orderLine?.productVariant
  const product = productVariant?.product
  const type = orderLine?.customFields?.type
  const quantity = orderLine?.quantity
  const [offer, setOffer] = useState(
    productVariant
      ? OfferUtils.getCurrentVariantOffer(
          productVariant,
          type,
          process.env.GATSBY_API_CHANNEL_ID,
          currentUser
        )
      : OfferUtils.getCurrentProductOffer(
          product,
          type,
          process.env.GATSBY_API_CHANNEL_ID,
          currentUser
        )
  )

  useEffect(() => {
    setOffer(
      productVariant
        ? OfferUtils.getCurrentVariantOffer(
            productVariant,
            type,
            process.env.GATSBY_API_CHANNEL_ID,
            currentUser
          )
        : OfferUtils.getCurrentProductOffer(
            product,
            type,
            process.env.GATSBY_API_CHANNEL_ID,
            currentUser
          )
    )
  }, [currentUser, product, productVariant])

  return (
    <Flex direction="column" w="100%">
      <Flex
        w="100%"
        direction="row"
        alignItems="center"
        justify={{ base: 'flex-start', md: 'flex-end' }}>
        <Flex
          direction="column"
          align={offer?.discount !== 0 ? 'space-between' : { base: 'flex-start', md: 'flex-end' }}>
          <Flex flexDirection="column" mb="6px" align="baseline" justify="flex-end">
            <Flex direction="row" alignSelf="flex-end" align="center">
              <Flex h="26px" align="center" alignSelf="flex-end">
                {offer?.advantageType === 'PTRG' && <RedPoint />}
              </Flex>
              {offer?.discount !== 0 && (
                <Flex
                  bgColor="#d5e301"
                  justify="center"
                  alignSelf="flex-end"
                  align="center"
                  minW={{ base: '34px', md: '40px' }}
                  pl="3px"
                  pr="3px"
                  h="26px"
                  mr={{ base: '4px', md: '7px' }}
                  style={{ borderRadius: '3px' }}>
                  <Text
                    as="span"
                    fontSize={{ base: '15.5px', md: '18px' }}
                    style={{
                      fontWeight: 700,
                      color: '#242a30',
                      fontFamily: 'PT Sans Narrow,PT Sans,Arial,sans-serif'
                    }}>
                    {offer?.discountType === 'PERCENTAGE'
                      ? `-${offer?.discount}`
                      : `-${priceFormat(offer?.discount, offer?.currency)}`}
                  </Text>
                  <span
                    style={{
                      fontSize: '9px',
                      fontWeight: 700,
                      color: '#242a30',
                      paddingBottom: '7px'
                    }}>
                    {`${offer?.discountType === 'PERCENTAGE' ? '%' : ''}`}
                  </span>
                </Flex>
              )}

              {isLoyalty && orderLine?.customFields?.isLoyaltyEligible && <LoyaltyAdvantage channelLoyaltyAdvantage={websiteContext?.customFields?.loyaltyAdvantage || 10} />}
              <Flex direction="column" width={{ base: '100%', md: 'auto' }}>
                {(offer?.discount !== 0 ||
                  (isLoyalty && orderLine?.customFields?.isLoyaltyEligible)) && (
                  <Flex direction="row">
                    <Text
                      as="span"
                      fontSize={{ base: '14px', md: '16px' }}
                      mr="0"
                      alignSelf={{ base: 'start', md: 'end' }}
                      style={{
                        textDecoration: 'line-through',
                        color: '#999',
                        fontFamily: 'PT Sans Narrow,PT Sans,Arial,sans-serif'
                      }}>
                      {`${priceFormat(quantity * offer?.standardPrice, offer?.currency)}`}
                    </Text>
                  </Flex>
                )}
                <Flex direction="row" align="center">
                  <Text
                    as="span"
                    fontSize={{ base: '18px', md: '22px' }}
                    alignSelf="center"
                    style={{
                      color: 'rgb(51,51,51)',
                      lineHeight: 1.2,
                      fontWeight: 700,
                      fontFamily: 'PT Sans Narrow,PT Sans,Arial,sans-serif'
                    }}>{`${priceFormat(orderLine?.linePriceWithTax, offer?.currency)}`}</Text>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
          <PopoverEcopart
            price={offer?.price}
            deee={offer?.deee}
            ecomob={offer?.ecomob}
            quantity={quantity}
            currency={offer?.currency}
            showPopover={false}
          />
        </Flex>
      </Flex>
      {!isLoyalty && orderLine?.customFields?.isLoyaltyEligible && (
        <Flex direction="column" align={['flex-start', 'flex-end']} style={{ paddingTop: '20px' }}>
          <Text style={{ fontSize: '16px' }}>Prix fidélité : -{websiteContext?.customFields?.loyaltyAdvantage || 10}%</Text>
          <Text>
            soit{' '}
            <span style={{ fontSize: '16px', fontWeight: '600' }}>{` ${priceFormat(
              quantity * (offer?.price - orderLine?.customFields?.loyaltyDiscount),
              offer?.currency
            )}`}</span>
          </Text>
        </Flex>
      )}

      {isLoyalty && orderLine?.customFields?.isLoyaltyEligible && (
        <Text w="100%" pt="20px" textAlign={{ base: 'left', md: 'right' }}>
          Prix fidélité appliqué
        </Text>
      )}
    </Flex>
  )
}

export default memo(CheckoutPrice)
