import { Flex, Link, Text } from '@stars-ecom/shared-atoms-ui'
import React, { memo, useContext } from 'react'
import { WebsiteContext } from '../../context'

import { OfferUtils, priceFormat } from '../../utils'
import { PopoverEcopart } from './Ecopart'

const _AddToCartPopinPrice = (props) => {
  const websiteContext = useContext(WebsiteContext);

  const { product, productVariant, type = 'STANDARD', quantity = 1 } = props

  const {
    standardPrice = 0,
    price = 0,
    discount = 0,
    discountType = 'PERCENTAGE',
    deee = 0,
    ecomob = 0,
    currency = 'EUR',
    advantageType
  } = productVariant
    ? OfferUtils.getCurrentVariantOffer(productVariant, type, process.env.GATSBY_API_CHANNEL_ID)
    : OfferUtils.getCurrentProductOffer(product, type, process.env.GATSBY_API_CHANNEL_ID)

  const loyaltyEligible = advantageType !== 'PTRG'

  return (
    <Flex direction="column" w="100%" maxWidth={{ md: '250px' }}>
      <Flex
        w="100%"
        direction="row"
        justify={discount !== 0 ? 'space-between' : 'flex-end'}
        align="flex-start">
        {discount !== 0 && (
          <Flex
            bgColor="#d5e301"
            justify="center"
            align="center"
            w="40px"
            h="26px"
            mr="8px"
            style={{ borderRadius: '3px' }}>
            <span
              style={{
                fontSize: '18px',
                fontWeight: 700,
                color: '#242a30',
                fontFamily: 'PT Sans Narrow,PT Sans,Arial,sans-serif'
              }}>
              {discountType === 'PERCENTAGE'
                ? `-${discount}`
                : `-${priceFormat(discount, currency)}`}
            </span>
            <span
              style={{
                fontSize: '10px',
                fontWeight: 700,
                color: '#242a30',
                paddingBottom: '10px'
              }}>
              {`${discountType === 'PERCENTAGE' ? '%' : ''}`}
            </span>
          </Flex>
        )}
        <Flex direction="column" align="flex-end" alignSelf="flex-end">
          <Flex flexDirection="row" align="flex-end" justify="flex-end">
            {discount !== 0 && (
              <Text
                as="span"
                fontSize="14px"
                mr="0"
                alignSelf={{ base: 'start', md: 'end' }}
                lineHeight="initial"
                pr="4px"
                style={{
                  textDecoration: 'line-through',
                  color: '#999',
                  fontFamily: 'PT Sans,Arial,sans-serif'
                }}>{`${priceFormat(standardPrice, currency)}`}</Text>
            )}
            <span
              style={{
                color: '#444',
                fontSize: '22px',
                lineHeight: 1,
                fontWeight: 700,
                fontFamily: 'PT Sans,Arial,sans-serif'
              }}>{`${
              discount !== 0 ? priceFormat(price, currency) : priceFormat(standardPrice, currency)
            }`}</span>
          </Flex>
        </Flex>
      </Flex>
      <PopoverEcopart
        price={price}
        deee={deee}
        ecomob={ecomob}
        quantity={quantity}
        currency={currency}
        showPopover={false}
      />
      {loyaltyEligible ? (
        <>
          <Flex direction="column" align="flex-end" style={{ paddingTop: '20px' }}>
            <span
              style={{
                fontFamily: 'PT Sans, Arial, Helvetica',
                fontSize: '14px',
                fontWeight: '700',
                color: 'rgb(51,51,51)'
              }}>
              -{websiteContext?.customFields?.loyaltyAdvantage || 10}% supplémentaires
            </span>
            <span style={{ fontSize: '14px', color: 'rgb(51,51,51)' }}>
              avec la{' '}
              <Link to="/fidelite" target="_blank" style={{ textDecoration: 'underline' }}>
                carte de fidélité
              </Link>{' '}
              soit{' '}
              <span style={{ fontWeight: '700' }}>{`${priceFormat(
                (price - (deee || 0) - (ecomob || 0)) * (1 - ((websiteContext?.customFields?.loyaltyAdvantage || 10) / 100)) + (deee || 0) + (ecomob || 0),
                currency
              )}`}</span>
            </span>
          </Flex>
        </>
      ) : (
        <Flex direction="column" align="flex-end" style={{ paddingTop: '20px' }}>
          <Flex direction="row" align="center">
            <div
              style={{
                backgroundColor: '#b30f16',
                borderRadius: '5px',
                width: '10px',
                height: '10px',
                marginRight: '5px'
              }}
            />
            <span
              style={{
                fontSize: '14px',
                fontFamily: 'PT Sans, Arial, Helvetica',
                lineHeight: '16px',
                fontWeight: 700,
                color: '#b30f16'
              }}>
              POINT ROUGE
            </span>
          </Flex>
          <span
            style={{
              marginTop: '5px',
              fontSize: '14px',
              lineHeight: '16px',
              fontWeight: 400,
              color: '#000000',
              textAlign: 'right',
              fontFamily: 'PT Sans, Arial, Helvetica'
            }}>
            Ce produit ne bénéficie pas
            <br />
            de la remise fidélité
          </span>
        </Flex>
      )}
    </Flex>
  )
}

export default memo(_AddToCartPopinPrice)
