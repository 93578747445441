import {
  Box,
  ChakraIcons,
  CircularProgress,
  Divider,
  ErrorModal,
  Flex,
  GatsbyImage,
  Picto,
  Select,
  Switch,
  Text,
  useDisclosure,
  useState
} from '@stars-ecom/shared-atoms-ui'
import { Link } from 'gatsby'
import head from 'lodash/head'
import isEmpty from 'lodash/isEmpty'
import React, { useContext, useEffect } from 'react'

import { MaxProductModal } from '../cart'
import { ApiContext, CartContext, DataLayerUtils, UserContext, WebsiteContext } from '../context'
import CheckoutPrice from '../product/price/CheckoutPrice'
import ProductInventory from '../product/ProductInventory'
import { InventoryUtils, priceFormat } from '../utils'
import { getCurrentProductOffer } from '../utils/offerUtils'

const OptionQuantity = () => {
  var options = []
  for (var i = 1; i <= 10; i++) {
    options.push(
      <option value={i} key={i}>
        {i}
      </option>
    )
  }
  return options
}

const OrderLineDetail = (props) => {
  const {
    isOpen: isOpenOrderLimitErrorModal,
    onOpen: onOpenOrderLimitErrorModal,
    onClose: onCloseOrderLimitErrorModal
  } = useDisclosure()
  const {
    isOpen: isOpenErrorModal,
    onOpen: onOpenErrorModal,
    onClose: onCloseErrorModal
  } = useDisclosure()

  const { orderLine, resetErrorCode = () => {}, totalProductQuantity, errorManager } = props
  const apiContext = useContext(ApiContext)
  const currentCart = useContext(CartContext)
  const currentUser = useContext(UserContext)
  const websiteContext = useContext(WebsiteContext)
  const [quantity, setQuantity] = useState(orderLine?.quantity)
  const [loading, setLoading] = useState(false)
  const [stockLevel, setStockLevel] = useState()

  const getInventoryData = async () => {
    const [directStockLevel, promiseStockLevel] = await InventoryUtils.getStockLevel(
      null,
      orderLine?.productVariant,
      apiContext?.InventoryApi
    )
    setStockLevel(directStockLevel)
    promiseStockLevel
      ?.then((result) => setStockLevel(result?.stockLevel))
      ?.catch((err) => {
        console.error(err)
      })
  }

  const checkValidCouponCode = async () => {
    if (currentCart.couponCodes.length > 0) {
      const couponCode = currentCart.couponCodes[0];
      await apiContext?.OrderApi?.removeCouponCode(couponCode);
      const result = await apiContext?.OrderApi?.applyCouponCode(couponCode)
      const error = result?.errorCode || result?.error;
      if (error) {
        if (error === 'COUPON_CODE_LIMIT_ERROR' || error === 'COUPON_CODE_ALREADY_APPLIED_ERROR') {
          errorManager.setErrorMessage('Vous ne pouvez plus bénéficier des avantages liés à ce code promo. Celui-ci a déjà été utilisé.')
        } else if (error === 'COUPON_CODE_NOT_APPLICABLE_ERROR') {
          errorManager.setErrorMessage('Vous ne pouvez pas bénéficier des avantages liés à ce code promo.')
        } else if (error === 'COUPON_CODE_EXPIRED_ERROR') {
          errorManager.setErrorMessage("Le code que vous avez saisi n'est plus valide.")
        } else if (error === "COUPON_CODE_CONDITION_ERROR") {
          errorManager.setErrorMessage("Votre commande ne satisfait pas aux conditions d'application du code promo.")
        } else {
          errorManager.setErrorMessage("Le code que vous avez saisi n'existe pas ou n'est pas valable.")
        }
      } else {
        errorManager.setErrorMessage('');
      }
    } else if (errorManager.errorMessage !== '') {
      errorManager.setErrorMessage('');
    }
    return true;
  }

  useEffect(() => {
    getInventoryData()
  }, [orderLine?.productVariant?.id])

  const editQuantity = async (orderLine, newQuantity) => {
    setQuantity(newQuantity)
    setLoading(true)
    const result = await apiContext?.OrderApi?.adjustOrderLine(orderLine.id, parseInt(newQuantity))
    
    await checkValidCouponCode();
    
    setLoading(false)

    if (result && result?.__typename === 'OrderLimitError') {
      onOpenOrderLimitErrorModal()
    } else if (!result || result?.__typename !== 'Order') {
      onOpenErrorModal()
    } else {
      if (newQuantity > orderLine?.quantity) {
        DataLayerUtils.addToCart({
          orderCode: currentCart?.code,
          product: orderLine?.productVariant?.product,
          productVariant: orderLine?.productVariant,
          stockLevel: orderLine?.customFields?.stockLevel,
          quantity: newQuantity - orderLine?.quantity,
          offerType: orderLine?.customFields?.type
        })
      } else {
        DataLayerUtils.removeFromCart({
          orderCode: currentCart?.code,
          product: orderLine?.productVariant?.product,
          productVariant: orderLine?.productVariant,
          stockLevel: orderLine?.customFields?.stockLevel,
          quantity: orderLine?.quantity - newQuantity,
          offerType: orderLine?.customFields?.type
        })
      }
    }

    resetErrorCode()
  }

  const deleteProduct = async (orderLine) => {
    setLoading(true)
    await apiContext?.OrderApi?.removeOrderLine(orderLine.id);
    checkValidCouponCode();
    DataLayerUtils.removeFromCart({
      orderCode: currentCart?.code,
      product: orderLine?.productVariant?.product,
      productVariant: orderLine?.productVariant,
      stockLevel: orderLine?.customFields?.stockLevel,
      quantity: orderLine?.quantity,
      offerType: orderLine?.customFields?.type
    })
    resetErrorCode()
  }

  useEffect(() => {
    if (quantity !== orderLine.quantity) {
      setQuantity(orderLine.quantity)
    }
  }, [orderLine])

  return (
    <Flex
      position="relative"
      direction="column"
      w="100%"
      order={
        orderLine?.productVariant?.product?.customFields?.productType === 'FID'
          ? totalProductQuantity
          : 0
      }>
      <Flex
        borderTop="1px solid #cfd5e3"
        p={{ base: '8px 0', md: '10px' }}
        flexDirection={{ base: 'column', md: 'row' }}
        justifyContent="space-between"
        w="100%">
        <Flex w="auto">
          <Link
            to={
              orderLine?.productVariant?.product?.customFields?.productType === 'FID'
                ? '/fidelite'
                : `/${orderLine?.productVariant?.product?.customFields?.urlKey}`
            }>
            <Flex width={{ base: '70px', md: '130px' }} align="center" justify="center">
              <GatsbyImage
                image={
                  orderLine?.productVariant?.product?.featuredAsset?.image?.childImageSharp
                    ?.gatsbyImageData
                }
                alt={orderLine?.productVariant?.name}
              />
            </Flex>
          </Link>

          <Flex
            flexDirection="column"
            justifyContent="space-between"
            ml={{ base: '7px', md: '20px' }}
            w="100%"
            minWidth="150px"
            maxWidth="205px">
            <Box>
              <Link
                to={
                  orderLine?.productVariant?.product?.customFields?.productType === 'FID'
                    ? '/fidelite'
                    : `/${orderLine?.productVariant?.product?.customFields?.urlKey}`
                }>
                <Text
                  fontWeight="900"
                  fontSize="15px"
                  w={{ base: '80%', md: '100%' }}
                  maxW={{ base: '80%', md: '190px' }}
                  style={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    WebkitLineClamp: 2,
                    display: '-webkit-box',
                    WebkitBoxOrient: 'vertical'
                  }}>
                  {orderLine.productVariant.name}
                </Text>
              </Link>

              <ProductInventory stockLevel={stockLevel} isCheckout={true} />
            </Box>
            <Flex alignItems="center" mt={{ base: '15px', md: '50px' }}>
              {orderLine.productVariant.product.customFields.productType !== 'FID' && (
                <Select
                  maxW="65px"
                  style={{ paddingLeft: '8px', textAlign: 'center' }}
                  iconSize={{ base: '12px', md: '18px' }}
                  icon={
                    websiteContext?.isMobile ? (
                      <ChakraIcons.ArrowUpDownIcon />
                    ) : (
                      <ChakraIcons.ChevronDownIcon />
                    )
                  }
                  mr="15px"
                  value={quantity}
                  onChange={(e) => editQuantity(orderLine, e.target.value)}>
                  <OptionQuantity />
                </Select>
              )}
              <Picto
                icon="trashBin"
                width="22px"
                height="22px"
                color="#999999"
                onClick={() => deleteProduct(orderLine)}
                style={{ cursor: 'pointer' }}></Picto>
            </Flex>
          </Flex>
        </Flex>

        <Flex
          direction="column"
          justify="space-between"
          w={{ base: '100%', md: 'auto' }}
          pl={{ base: '74px', md: '0' }}
          pt={{ base: '10px', md: '0' }}>
          <CheckoutPrice
            padding="0"
            backgroundColor="transparent"
            orderLine={orderLine}
            isLoyalty={currentCart?.customFields?.isLoyalty}
            currentUser={currentUser}
          />
        </Flex>
      </Flex>
      {!isEmpty(orderLine?.productVariant?.product?.customFields?.warrantyExtension) && (
        <ProductWarranty
          warrantyExtension={head(
            orderLine?.productVariant?.product?.customFields?.warrantyExtension
          )}
          parentQuantity={orderLine?.quantity}
          parentOrderLineId={orderLine?.id}
          orderLines={currentCart?.lines}
        />
      )}
      <Divider />
      {loading && (
        <Flex
          align="center"
          position="absolute"
          justify="center"
          w="100%"
          h="100%"
          background="rgba(0, 0, 0, 0.2)">
          <CircularProgress isIndeterminate={true} color={websiteContext?.mainColor} />
        </Flex>
      )}
      <MaxProductModal
        isOpen={isOpenOrderLimitErrorModal}
        onOpen={onOpenOrderLimitErrorModal}
        onClose={onCloseOrderLimitErrorModal}
      />
      <ErrorModal
        isOpen={isOpenErrorModal}
        onOpen={onOpenErrorModal}
        onClose={onCloseErrorModal}
        icon="informationCercle"
        message="Une erreur est survenue lors de l'ajout au panier!"
        color={websiteContext?.mainColor}
      />
    </Flex>
  )
}

const OrderItemsDetail = ({ errorManager }) => {
  const currentCart = useContext(CartContext)

  return (
    <Flex flexDirection="column" fontFamily="PT Sans, Arial, sans-serif" w="100%">
      {currentCart?.lines
        ?.filter((ol) =>
          ['PDT', 'FID'].includes(ol.productVariant.product.customFields.productType)
        )
        .map((ol) => (
          <OrderLineDetail
            key={`order_line_${ol.id}`}
            orderLine={ol}
            errorManager={errorManager}
            totalProductQuantity={currentCart?.customFields?.totalProductQuantity}
          />
        ))}
    </Flex>
  )
}

const ProductWarranty = (props) => {
  const { warrantyExtension, parentOrderLineId, orderLines, parentQuantity } = props
  const [active, setActive] = useState(false)
  const apiContext = useContext(ApiContext)

  const handleWarranty = async (value) => {
    if (value) {
      setActive(true)
      await apiContext.OrderApi.addItemToOrder(
        head(warrantyExtension.variants)?.id,
        1,
        'STANDARD',
        parentOrderLineId
      )
    } else {
      setActive(false)
      const result = orderLines.find((ol) => {
        return (
          ol.customFields.parentOrderLine === parentOrderLineId &&
          ol.productVariant.product.id === warrantyExtension.id
        )
      })
      if (result) {
        await apiContext.OrderApi.removeOrderLine(result.id)
      }
    }
  }

  useEffect(() => {
    const result = orderLines.find(
      (ol) =>
        ol.customFields.parentOrderLine == parentOrderLineId &&
        ol.productVariant.product.id == warrantyExtension.id
    )
    if (result) {
      setActive(true)
    } else {
      setActive(false)
    }
  }, [orderLines])
  const { price = 0, currency = 'EUR' } = getCurrentProductOffer(
    warrantyExtension,
    'STANDARD',
    process.env.GATSBY_API_CHANNEL_ID
  )

  return (
    <Flex
      border="1px solid #cfd5e3"
      mb="15px"
      p={{ base: '10px 20px 10px 0', md: '10px 20px' }}
      alignItems="center">
      <Switch
        w={{ base: '70px', md: 'auto' }}
        m={{ base: '0 14px' }}
        textAlign="center"
        isChecked={active}
        onChange={(e) => {
          handleWarranty(e.target.checked)
        }}
        sx={{
          '& > span': {
            backgroundColor: active ? '#39a132 !important' : '#000'
          },
          '& > span:focus, & > span[data-focus]': {
            boxShadow: 'none !important'
          }
        }}
      />
      {!active ? (
        <Box ml={{ md: '12px' }} w={{ base: '48%', md: '65%' }}>
          <Text as="u">Étendre ma garantie :</Text>
          <Text>
            {`prolonger de ${Math.round(warrantyExtension?.customFields?.warranty / 12, 1)}`}{' '}
            {Math.round(warrantyExtension?.customFields?.warranty / 12, 1) > 1 ? ' ans ' : ' an '}{' '}
            {`pour ${priceFormat(price * parentQuantity, currency)}`}
          </Text>
        </Box>
      ) : (
        <Flex
          w={{ base: '70%', md: '100%' }}
          justify="space-between"
          ml={{ md: '12px' }}
          h={{ md: '48px' }}
          align="center">
          <Text as="u" w="65%">
            {`Garantie de ${Math.round(warrantyExtension?.customFields?.warranty / 12, 1)}`}
            {Math.round(warrantyExtension?.customFields?.warranty / 12, 1) > 1
              ? ' ans supplémentaires ajoutée'
              : ' an supplémentaire ajoutée'}
          </Text>
          <Text
            fontFamily="PT Sans Narrow, PT Sans, Arial, sans-serif"
            fontSize="22px"
            fontWeight="700">
            {priceFormat(price * parentQuantity || 0, currency)}
          </Text>
        </Flex>
      )}
    </Flex>
  )
}

export default OrderItemsDetail
