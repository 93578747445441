import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Divider,
  Flex,
  Image,
  Picto,
  Text
} from '@stars-ecom/shared-atoms-ui'
import head from 'lodash/head'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import set from 'lodash/set'
import times from 'lodash/times'
import React, { useContext, useEffect, useState } from 'react'

import { ApiContext, CartContext, PaymentContext, WebsiteContext } from '../context'
import logoMR from '../shipping/mondialRelay/assets/mr-64.png'
import { priceFormat, ShippingUtils } from '../utils'

const OrderDetails = (props) => {
  const { details } = props

  return (
    <Accordion allowMultiple>
      {details
        ?.filter((d) => d.price)
        ?.map((item, i) => (
          <AccordionItem key={`item-${i}`} borderTop="0" borderBottom="1px solid #d4d4d4">
            <AccordionButton pl="0" pr="4px" _focus={{ border: '0' }}>
              <Box
                fontFamily="PT Sans, Arial, sans-serif"
                fontSize="14px"
                color="#777777"
                flex="1"
                textAlign="left"
                pl="24px"
                overflow="hidden"
                style={{
                  textOverflow: 'ellipsis',
                  WebkitLineClamp: 1,
                  display: '-webkit-box',
                  WebkitBoxOrient: 'vertical'
                }}>
                {item.title}
              </Box>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel pb={4}>
              <Text
                fontFamily="PT Sans, Arial, sans-serif"
                fontSize="14px"
                color="#777777"
                width="100%"
                textAlign="right">
                {priceFormat(item.price, 'EUR', "Offert")}
              </Text>
            </AccordionPanel>
          </AccordionItem>
        ))}
    </Accordion>
  )
}

const Addresses = () => {
  const currentCart = useContext(CartContext)
  const [shipping, setShipping] = useState({})
  useEffect(() => {
    const newShipping = {}
    const address = currentCart?.shippingAddress
    const hasHeavyProduct = ShippingUtils.getHasHeavyProduct(currentCart)
    const hasLightProduct = ShippingUtils.getHasLightProduct(currentCart)

    if (hasHeavyProduct && address && !isNil(address.city)) {
      set(newShipping, 'heavy.address', { ...address, type: 'standard' })
    }
    if (hasLightProduct) {
      if (head(currentCart?.shippingLines)?.shippingMethod?.code === 'mondial-relay') {
        set(newShipping, 'light.address', {
          type: 'mondial-relay',
          id: currentCart?.customFields?.relayCode,
          streetLine1: currentCart?.customFields?.relayName,
          streetLine2: currentCart?.customFields?.relayAddress,
          city: currentCart?.customFields?.relayCity,
          postalCode: currentCart?.customFields?.relayPostalCode
        })
      } else {
        set(newShipping, 'light.address', {
          ...address,
          type: head(currentCart?.shippingLines)?.shippingMethod?.code
        })
      }
    }

    setShipping(newShipping)
  }, [currentCart])
  const Wrapper = ({ children }) =>
    Object.keys(shipping || {}).length > 1 ? (
      <Accordion allowMultiple defaultIndex={times(Object.keys(shipping || {}).length)}>
        {children}
      </Accordion>
    ) : (
      <Flex borderBottom="1px solid #d4d4d4">{children}</Flex>
    )
  const Address = ({
    step,
    type,
    name = '',
    line1 = '',
    line2 = '',
    postalCode = '',
    city = '',
    isAccordionItem
  }) => {
    const ItemWrapper = ({ children, type }) =>
      isAccordionItem ? (
        <AccordionItem key={`item-${type}`} w="100%">
          <AccordionButton px="0" w="100%" _focus={{ border: '0' }}>
            <Box
              flex="1"
              fontFamily="PT Sans, Arial, sans-serif"
              fontSize="14px"
              color="#777"
              textAlign="left"
              pl="24px">
              {type === 'heavy' ? 'Produit(s) en livraison standard' : 'Autre(s) produit(s)'}
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel p="0" w="100%" bg="#fff" border="1px solid #d4d4d4">
            {children}
          </AccordionPanel>
        </AccordionItem>
      ) : (
        <Flex w="100%">{children}</Flex>
      )
    const getShippingTypeText = () => {
      switch (type) {
        case 'mondial-relay':
          return 'Retrait en point Relais Mondial Relay'
        case 'express':
          return 'Livraison Express à domicile'
        default:
          return 'Livraison à domicile'
      }
    }
    return (
      <ItemWrapper type={step}>
        <Flex py="20px" w="100%">
          {type === 'mondial-relay' ? (
            <Image
              src={logoMR}
              width="24px"
              height="24px"
              style={{ marginLeft: '10px', marginRight: '15px' }}
              alt="mondial relay"
            />
          ) : (
            <Picto
              icon={type === 'standard' ? 'shipping' : 'shippingExpress'}
              width="52px"
              height="32px"
              color="#777"
              style={{ paddingLeft: '10px', paddingRight: '10px', marginTop: '-7px' }}
            />
          )}
          <Box
            w="100%"
            fontFamily="PT Sans, Arial, sans-serif"
            color="#8B8B8B"
            fontSize="14px"
            lineHeight="18px">
            <Text maxW={type === 'mondial-relay' ? '150px' : 'unset'} mb="10px">
              {getShippingTypeText()}
            </Text>
            {type !== 'mondial-relay' && <Text>{name}</Text>}
            <Text>{line1 || ''}</Text>
            <Text>{line2 || ''}</Text>
            <Text>{`${postalCode || ''} ${city || ''}`}</Text>
          </Box>
        </Flex>
      </ItemWrapper>
    )
  }
  return (
    <Flex direction="column" w="100%">
      <Wrapper>
        {Object.keys(shipping).map((k) => {
          if (isEmpty(shipping[k]?.address)) {
            return null
          } else {
            return (
              <Address
                key={k}
                step={k}
                type={shipping[k]?.address?.type}
                name={`${shipping[k]?.address?.customFields?.firstName || ''} ${
                  shipping[k]?.address?.customFields?.lastName || ''
                }`}
                line1={shipping[k]?.address?.streetLine1}
                line2={shipping[k]?.address?.streetLine2}
                postalCode={shipping[k]?.address?.postalCode}
                city={shipping[k]?.address?.city}
                phone={shipping[k]?.address?.customFields?.cellPhoneNumber}
                isAccordionItem={Object.keys(shipping)?.length > 1}
              />
            )
          }
        })}
      </Wrapper>
    </Flex>
  )
}

const CheckoutShippingSummary = () => {
  const websiteContext = useContext(WebsiteContext)
  const currentCart = useContext(CartContext)
  const apiContext = useContext(ApiContext)
  const { paymentMethod } = useContext(PaymentContext)

  console.log('pm', paymentMethod)
  const multiplePaymentLabel = (code) => {
    switch (code) {
      case 'multiple2':
        return 'en 2 fois'
      case 'multiple3':
        return 'en 3 fois'
      case 'multiple4':
        return 'en 4 fois'
      default:
        return ''
    }
  }

  const isShippingPromo = () => {
    if (
      currentCart?.customFields?.totalDefaultShippingFee > 0 &&
      currentCart?.customFields?.totalDefaultShippingFee > currentCart?.shippingWithTax
    ) {
      return (
        <Text fontSize="12px" fontStyle="italic" style={{ color: "#cd2acd"}}>
          Vous bénéficiez de l'avantage frais de port à l'article offerts
        </Text>
      )
    } else if (
      currentCart?.customFields?.shippingApplicationFee > 0 &&
      currentCart?.shippingWithTax === currentCart?.customFields?.totalDefaultShippingFee &&
      head(currentCart?.shippingLines)?.shippingMethod?.code === 'express'
    ) {
      return (
        <Text fontSize="12px" fontStyle="italic" style={{ color: "#cd2acd"}}>
          Vous bénéficiez de l'avantage frais de livraison express offert
        </Text>
      )
    }
  }

  return (
    <Flex w={['100%', '430px']} flexDirection="column">
      <Accordion defaultIndex={[0]} allowToggle>
        <AccordionItem style={{ borderBottomWidth: '0' }}>
          <AccordionButton p="0" style={{ boxShadow: 'none' }}>
            <Flex backgroundColor={websiteContext.mainColor} p="10px 20px" w="100%">
              <Flex color="white" align="center">
                <Text
                  fontFamily="PT Sans, Arial, Sans-serif"
                  as="span"
                  fontWeight="bold"
                  fontSize="22px"
                  mr="5px">
                  Récapitulatif
                </Text>
                <Text as="span" fontFamily="PT Sans, Arial, Sans-serif">
                  {` (${currentCart?.customFields?.totalProductQuantity} produit${
                    currentCart?.customFields?.totalProductQuantity > 1 ? 's' : ''
                  })`}
                </Text>
              </Flex>
              <AccordionIcon color="#FFFF" width="32px" height="32px" display={['flex', 'none']} />
            </Flex>
          </AccordionButton>
          <AccordionPanel p="20px" bg="#f7f7f7">
            <Box>
              <Flex pb="10px" justifyContent="space-between" align="center">
                <Text fontFamily="PT Sans, Arial, sans-serif" fontSize="16px">
                  Total commandé
                </Text>
                <Text fontWeight="bold" fontSize="22px">
                  {priceFormat(
                    currentCart
                      ? currentCart.subTotalWithTax +
                          (paymentMethod?.customFields?.applicationFee || 0)
                      : '0',
                    'EUR'
                  )}
                </Text>
              </Flex>
              <Divider borderColor="#d4d4d4" />
              <OrderDetails
                details={currentCart?.lines
                  ?.filter((ol) =>
                    ['FID', 'PDT'].includes(ol?.productVariant?.product?.customFields?.productType)
                  )
                  ?.sort((a, b) => {
                    if (a?.productVariant?.product?.customFields?.productType === 'FID') {
                      return 1
                    }
                    if (b?.productVariant?.product?.customFields?.productType === 'FID') {
                      return -1
                    }
                    return 0
                  })
                  ?.map((ol) => ({
                    title: ol.productVariant?.name,
                    price: ol?.linePriceWithTax
                  }))}
              />

              {(!isEmpty(currentCart?.discounts) ||
                (currentCart?.customFields?.totalWarrantyPrice > 0) ||
                (paymentMethod?.customFields?.applicationFee > 0)) && (
                <>
                  <OrderDetails
                    details={[
                      ...(currentCart?.discounts || []).map((d) => ({
                        title: 'Code promo',
                        price: d.amountWithTax
                      })),
                      {
                        title: 'Extension de garantie',
                        price: currentCart?.customFields?.totalWarrantyPrice || 0
                      },
                      {
                        title: `Frais de dossier paiement ${multiplePaymentLabel(
                          paymentMethod?.code
                        )} `,
                        price: paymentMethod?.customFields?.applicationFee || 0
                      }
                    ]}
                  />
                </>
              )}
              <Flex justify="space-between" flexDirection="column" py="10px">
                <Flex justify="space-between" align="center">
                  <Text as="u" fontFamily="PT Sans, Arial, sans-serif">
                    Frais de livraison
                  </Text>
                  <Text fontWeight="bold" fontSize="22px">
                    {priceFormat(currentCart?.shippingWithTax || 0, 'EUR', "Offert")}
                  </Text>
                </Flex>
                { isShippingPromo() }
              </Flex>
              <Divider borderColor="#d4d4d4" />
              <Addresses />
              <Flex pt="20px" justifyContent="space-between">
                <Text alignSelf="center" fontFamily="PT Sans, Arial, sans-serif">
                  Total
                </Text>
                <Text fontWeight="bold" fontSize="30px">
                  {priceFormat(
                    (currentCart?.totalWithTax || 0) +
                      (paymentMethod?.customFields?.applicationFee || 0),
                    'EUR',
                    'Offert'
                  )}
                </Text>
              </Flex>
            </Box>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Flex>
  )
}

export default CheckoutShippingSummary
